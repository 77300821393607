var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "批量添加品牌",
            width: "750px",
            top: "5vh",
            visible: _vm.showDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入关键词" },
                    model: {
                      value: _vm.form.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "keyword", $$v)
                      },
                      expression: "form.keyword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
              staticStyle: { height: "450px", overflow: "auto" },
            },
            [
              _vm.data.length != 0
                ? _c(
                    "div",
                    { staticClass: "df" },
                    _vm._l(_vm.data, function (item, index) {
                      return _c("el-checkbox", {
                        key: index,
                        attrs: { label: item.brand_name, border: "" },
                        model: {
                          value: item.is_close,
                          callback: function ($$v) {
                            _vm.$set(item, "is_close", $$v)
                          },
                          expression: "item.is_close",
                        },
                      })
                    }),
                    1
                  )
                : _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("暂无数据"),
                  ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }