<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加品牌"
      width="750px"
      top="15vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <div class="content">
        <el-form ref="form" :model="form" :rules="rules" label-width="85px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" style="width: 215px"></el-input>
          </el-form-item>
          <el-form-item label="上级名称">
            <!-- <el-input v-model="form.pid" style="width: 260px"></el-input> -->
            <el-cascader
              v-model="form.pid"
              :options="pidList"
              :props="props"
              style="width: 260px"
            ></el-cascader>
          </el-form-item>
          <div class="check">
            <el-radio v-model="status" label="0">正常</el-radio>
            <el-radio v-model="status" label="1">停用</el-radio>
          </div>
        </el-form>
      </div>

      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAdd">保存并新增</el-button>
        <el-button @click="justSave">保存</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { treeDetail, creatBrand } from '@/api/brand'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        form: {
          name: '',
          pid: '0',
        },
        pidList: [{}],
        pid: '0',
        status: '0',
        props: {
          value: 'id',
          label: 'brand_name',
          checkStrictly: true,
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {},

    watch: {
      async showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form.name = ''
          this.form.pid = '0'
        }
      },
    },
    methods: {
      async fetchData() {
        let { data } = await treeDetail()
        this.pidList = data
        this.pidList.forEach((item) => {
          if (item.children.length == 0) {
            delete item.children
          } else {
            item.children.forEach((item2) => {
              if (item2.children.length == 0) {
                delete item2.children
              } else {
                item2.children.forEach((item3) => {
                  if (item3.children.length == 0) {
                    delete item3.children
                  } else {
                    item3.children.forEach((item4) => {
                      if (item4.children.length == 0) {
                        delete item4.children
                      }
                    })
                  }
                })
              }
            })
          }
        })
        console.log(this.pidList)
      },
      saveAdd() {
        this.save(0)
      },
      justSave() {
        this.save(1)
      },
      save(addnew = false) {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            // console.log(this.pidList)
            // console.log(this.form.pid)
            // let na = this.pidList.filter((item) => item.id == this.form.pid)[0]

            // console.log(na)
            // 表单验证成功
            if (this.form.pid.length == 1) {
              this.form.pid = this.form.pid[0]
            } else {
              this.form.pid = this.form.pid[this.form.pid.length - 1]
            }

            console.log({
              is_close: this.status,
              brand_name: this.form.name,
              pid: this.form.pid,
            })
            creatBrand({
              is_close: this.status,
              brand_name: this.form.name,
              pid: this.form.pid,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.form.name = ''
                this.form.pid = '0'
                if (addnew) {
                  this.showDialog = false
                }
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    min-height: 160px;
    padding: 0px 15px;
  }
  .check {
    margin-top: 55px;
    text-align: center;
  }
</style>
