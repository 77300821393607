<template>
  <!-- NAME[epic=基础] 品牌资料 -->
  <div class="orderTest-container">
    <div class="topInput">
      <el-input
        v-model="queryForm.keyword"
        placeholder="品牌名称"
        :popper-class="'select-idx'"
        clearable
        style="width: 150px"
      ></el-input>
      <!-- <el-autocomplete
        v-model="queryForm.keyword"
        :fetch-suggestions="querySearch"
        placeholder="品牌名称"
        style="width: 150px"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="iconClick()"
        ></i>
        <template slot-scope="{ item }">
          <div class="wrap" style="display: flex">
            <div class="name" style="margin-right: 10px">id:{{ item.id }}</div>
            <span class="addr">商品名称:{{ item.value }}</span>
          </div>
        </template>
      </el-autocomplete> -->
      <el-select
        v-model="queryForm.is_close"
        placeholder="状态"
        clearable
        :popper-class="'select-idx'"
        style="width: 150px; margin: 0 15px"
      >
        <el-option
          v-for="item in saleManList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="query">查询</el-button>
      <el-button @click="quickAdd">快速添加</el-button>
      <el-button @click="addCate">添加品牌</el-button>
      <div class="table-wrapper">
        <el-table
          v-loading="tableLoading"
          stripe
          :data="tableData"
          style="width: 100%; margin-top: 10px"
          row-key="id"
          :indent="34"
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          >
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="50"
          ></el-table-column>
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="50"
          ></el-table-column>

          <el-table-column
            v-for="(colItem, index) in finallyColumns"
            :key="index"
            :prop="colItem.prop"
            :label="colItem.label"
            :width="colItem.width"
            :type="colItem.type"
            align="center"
          >
            <!-- 排序 -->
            <template v-if="colItem.label === '排序'" #default="{ row }">
              <el-input
                v-model.trim="row.sort"
                @change="blur_(row.id, row.sort)"
              ></el-input>
            </template>
            <template v-else #default="{ row }">
              <span>{{ row[colItem.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            show-overflow-tooltip
            width="120"
          >
            <template #default="{ $index, row }">
              <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
              <el-button
                type="text"
                @click.native.prevent="editRow($index, row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <quick-add ref="quickAdd" @refresh="fetchData"></quick-add>
    <add-brand ref="addBrand" @refresh="fetchData"></add-brand>
    <row-edit ref="rowEdit" @refresh="fetchData"></row-edit>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { getBrandTableList, refreshSort, deleteBrand } from '@/api/brand'
  import QuickAdd from './components/quickAdd'
  import AddBrand from './components/addBrand'
  import RowEdit from './components/edit'
  export default {
    name: 'BrandDetail',
    components: {
      QuickAdd,
      AddBrand,
      RowEdit,
    },
    data() {
      return {
        tableLoading: true,
        queryForm: {
          is_close: '',
          keyword: '',
        },
        saleManList: [
          {
            name: '正常',
            id: '0',
          },
          {
            name: '停用',
            id: '1',
          },
        ],
        defaultProps: {
          children: 'sub',
          label: 'brand_name',
        },
        checkList: ['排序', '品牌', '状态'],
        columns: [
          {
            order: 1,
            prop: 'sort',
            label: '排序',
            width: '70px',
            type: '',
          },
          {
            order: 1,
            prop: 'brand_name',
            label: '品牌',
            width: 'auto',
          },
          {
            order: 1,
            prop: 'is_close_txt',
            label: '状态',
            width: '90px',
          },
        ],
        tableData: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      iconClick() {},
      query() {
        this.fetchData()
      },
      quickAdd() {
        this.$refs['quickAdd'].showDialog = true
      },
      addCate() {
        this.$refs['addBrand'].showDialog = true
      },
      // 排序输入框失去焦点 排序
      blur_(rowId, rowSort) {
        refreshSort({
          id: rowId,
          sort: rowSort,
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success('排序成功')
            this.fetchData()
          }
        })
      },
      async querySearch(val, cb) {
        let selectData = this.selectRes.data.client
        console.log(selectData)
        var results = val
          ? selectData.filter(this.createStateFilter(val))
          : selectData
        cb(results)
      },
      async fetchData() {
        this.tableLoading = true
        let { data } = await getBrandTableList(this.queryForm)
        console.log('品牌数据')
        this.tableData = data.rows
        console.log(this.tableData)
        this.tableLoading = false
      },
      async deleteRow(index, row) {
        console.log('删除1  ')
        console.log(row.id)
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.deleteRowF(row)
          })
          .catch(() => {
            console.log('no')
          })
      },
      async deleteRowF(row) {
        let { code, msg, data } = await deleteBrand({ id: row.id })
        if (code !== 200) {
          this.$message.error(msg)
        } else {
          this.$message.success(msg)
          this.fetchData()
        }
      },
      editRow(index, row) {
        console.log('编辑')
        console.log(row.id)
        this.$refs['rowEdit'].row = row
        this.$refs['rowEdit'].showDialog = true
      },
    },
  }
</script>
