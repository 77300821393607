<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="编辑"
      width="750px"
      top="15vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <!-- {{ row.id }} -->
      <div class="content">
        <el-form
          ref="form"
          v-loading="formLoading"
          :model="form"
          :rules="rules"
          label-width="85px"
        >
          <el-form-item label="名称" prop="brand_name">
            <el-input v-model="form.brand_name" style="width: 260px"></el-input>
          </el-form-item>
          <el-form-item label="上级名称">
            <el-cascader
              v-model="form.pid"
              :options="pidList"
              :props="props"
              clearable
              style="width: 260px"
              @change="change"
            ></el-cascader>
          </el-form-item>
          <div class="check">
            <el-radio v-model="form.is_close" :label="0">正常</el-radio>
            <el-radio v-model="form.is_close" :label="1">停用</el-radio>
          </div>
        </el-form>
      </div>

      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { editBrand, editDetail } from '@/api/brand'
  export default {
    name: 'RowEdit',
    components: {},
    data() {
      return {
        formLoading: true,
        row: {},
        form: { brand_name: '' },
        showDialog: false,
        id: '',
        pid: 0,

        pid2: 0,
        pidList: [{}],
        props: {
          value: 'id',
          label: 'brand_name',
          checkStrictly: true,
        },
        rules: {
          brand_name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },

    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          let a = JSON.parse(JSON.stringify(this.row))
          this.id = a.id
          // this.form = a
          this.getRowDeatil()
        } else {
          this.formLoading = true
        }
      },
    },
    methods: {
      change(val) {
        console.log(val)
        let pid2
        if (val == 0) {
          this.pid2 = 0
        } else if (val.length == 1) {
          this.pid2 = val[0]
        } else if (val.length == 2) {
          this.pid2 = val[1]
        } else if (val.length == 3) {
          this.pid2 = val[2]
        } else if (val.length == 4) {
          this.pid2 = val[3]
        }
      },
      save() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            console.log(this.form.id)
            if (this.form.id.length) {
              this.pid = this.form.id[this.form.id.length - 1]
            } else {
              this.pid = 0
            }
            console.log({
              id: this.id,
              brand_name: this.form.brand_name,
              is_close: this.form.is_close,
              pid: this.pid2,
            })
            editBrand({
              id: this.id,
              brand_name: this.form.brand_name,
              is_close: this.form.is_close,
              pid: this.pid2,
            }).then((res) => {
              console.log(res)
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.showDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error('修改失败')
              }
            })
          } else {
            console.log('验证失败')
            return false
          }
        })
      },

      close() {
        this.showDialog = false
      },
      async getRowDeatil() {
        // 获取详情
        let { data } = await editDetail({ id: this.row.id })
        this.form = data.info
        this.pidList = data.parent
        this.pid = data.info.id
        console.log(this.form)
        console.log(this.pidList)

        this.pidList.forEach((item) => {
          console.log('1')
          if (item.children?.length == 0) {
            delete item.children
            console.log('2')
          } else {
            console.log('3')
            item.children?.forEach((item2) => {
              console.log('4')

              if (item2.children?.length == 0) {
                delete item2.children
              } else {
                item2.children?.forEach((item3) => {
                  if (item3.children?.length == 0) {
                    delete item3.children
                  } else {
                    item3.children?.forEach((item4) => {
                      if (item4.children?.length == 0) {
                        delete item4.children
                      }
                    })
                  }
                })
              }
            })
          }
        })
        this.formLoading = false
      },
      // fetchData() {},
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    min-height: 160px;
    padding: 0px 15px;
  }
  .check {
    margin-top: 55px;
    text-align: center;
  }
</style>
