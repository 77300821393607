var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "div",
        { staticClass: "topInput" },
        [
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: {
              placeholder: "品牌名称",
              "popper-class": "select-idx",
              clearable: "",
            },
            model: {
              value: _vm.queryForm.keyword,
              callback: function ($$v) {
                _vm.$set(_vm.queryForm, "keyword", $$v)
              },
              expression: "queryForm.keyword",
            },
          }),
          _c(
            "el-select",
            {
              staticStyle: { width: "150px", margin: "0 15px" },
              attrs: {
                placeholder: "状态",
                clearable: "",
                "popper-class": "select-idx",
              },
              model: {
                value: _vm.queryForm.is_close,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "is_close", $$v)
                },
                expression: "queryForm.is_close",
              },
            },
            _vm._l(_vm.saleManList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.query } },
            [_vm._v("查询")]
          ),
          _c("el-button", { on: { click: _vm.quickAdd } }, [
            _vm._v("快速添加"),
          ]),
          _c("el-button", { on: { click: _vm.addCate } }, [_vm._v("添加品牌")]),
          _c(
            "div",
            { staticClass: "table-wrapper" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    stripe: "",
                    data: _vm.tableData,
                    "row-key": "id",
                    indent: 34,
                    "default-expand-all": "",
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _vm._v(" > "),
                  _c("el-table-column", {
                    attrs: { align: "center", type: "index", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "50" },
                  }),
                  _vm._l(_vm.finallyColumns, function (colItem, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: colItem.prop,
                        label: colItem.label,
                        width: colItem.width,
                        type: colItem.type,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          colItem.label === "排序"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.blur_(row.id, row.sort)
                                        },
                                      },
                                      model: {
                                        value: row.sort,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row,
                                            "sort",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "row.sort",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row[colItem.prop])),
                                    ]),
                                  ]
                                },
                              },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.editRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("quick-add", { ref: "quickAdd", on: { refresh: _vm.fetchData } }),
      _c("add-brand", { ref: "addBrand", on: { refresh: _vm.fetchData } }),
      _c("row-edit", { ref: "rowEdit", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }