<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="批量添加品牌"
      width="750px"
      top="5vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="" prop="">
          <el-input
            v-model="form.keyword"
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <div
        v-loading="loading"
        class="content"
        style="height: 450px; overflow: auto"
      >
        <div v-if="data.length != 0" class="df">
          <el-checkbox
            v-for="(item, index) in data"
            :key="index"
            v-model="item.is_close"
            :label="item.brand_name"
            border
          ></el-checkbox>
        </div>
        <div v-else style="text-align: center">暂无数据</div>
      </div>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getQuickAddList, creatBrandData } from '@/api/brand'
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        loading: false,
        data: [],
        checked: {},
        form: {
          keyword: '',
          // pageSize: 10,
          // pageNo: 1,
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form.keyword = ''
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await getQuickAddList()
        console.log('快速添加列表数据2')
        console.log(data)
        this.data = data
        this.loading = false
      },
      submit() {
        let checked = this.data.filter((item) => item.is_close == true)
        let checkedId = []
        checked.forEach((item) => {
          checkedId.push(item.id)
        })
        let endData = checkedId.join(',')
        if (endData !== '') {
          creatBrandData({ ids: endData }).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功.')
              this.$emit('refresh')
              this.close()
            }
          })
        } else {
          this.$message.error('未选中任何选项')
        }
      },
      close() {
        this.showDialog = false
      },
      handleQuery() {
        this.loading = true
        postAction('/baseAdmin/brand/quick-view', this.form).then((r) => {
          console.log('r', r)
          this.data = r.data
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    min-height: 260px;
    padding: 0px 15px;
  }
  .df {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    label {
      width: 120px;
      margin: 10px;
    }
  }
</style>
